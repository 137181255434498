body
  font-family: "Josefin Sans"
  background: black
  color: #75F5FF
  overflow: hidden
  animation: snow 30s linear infinite

#wrapper
  padding: 100px

#limit
  margin-top: 30px
  margin-bottom: 0
  font-size: 100px
  .days
    &:after
      content: "Days"
      font-size: .6em

a
  color: orange
  &:vlink
    color: orange

span
  padding: 0 5px
  &.blue
    color: blue

  &.red
    color: red

.white
  color: white

.name
  color: white

.last-time
  color: #353535

.umaru
  height: 300px
  width: 266px
  background: url("/image/umaru.png")

.flex
  display: flex

.alert
  border-left: double 10px yellow
  padding-left: 5px
  color: yellow

#config-form
  font-size: 1.5em
  fieldset
    padding-left: 20px
    p
      label
        margin-right: 5px
        display: inline-block
        width: 10rem
        &:after
          content: ':'
      input
        background: black
        border: none
        border-bottom: 1px solid
